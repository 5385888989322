import { BaseDto } from './base-dto';
export class paciente extends BaseDto {
    public numero!: number;
    public nombre!: string;
    public apellidos!: string;
    public email!: string;
    public telefono1!: number;
    public telefono2!: number;
    public nif!: string;
    public direccion!: string;
    public codigopostal!: number;
    public poblacion!: string;
    public pais!: string;
    // public fotografia!: string;
    public observaciones!: string;
    public token_notificaciones!: string;
    public pass_paciente!: string;
    public uid!: string;
    public sexo!: string;
    public fecha_alta!: Date;
    public lopd_aceptado!: boolean;
    public notificacion_agua!: boolean;
    public ocultar_cantidades!: boolean;
    public ocultar_recetas!: boolean;
}
