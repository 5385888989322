import { getModule, Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { store } from '@/store/store';
import { API } from '@/shared/api';
import { paciente } from '@/shared/dtos/paciente-dto';
import { ssmHttpService } from '@/shared/services/http-service';

@Module({
    namespaced: true,
    name: 'pacienteModule',
    store,
    dynamic: true,
})

class pacientesModule extends VuexModule {
    public paciente: paciente = new paciente();
    public token: string = "";

    @Action({ commit: 'onGetpaciente' })
    public async getpaciente() {
        return await ssmHttpService.get(API.paciente + '/datos_paciente_app/', null, true, false, true);
    }

    @Action({})
    public async actualizarpaciente(paciente: paciente) {
        await ssmHttpService.put(API.paciente + '/' + paciente.numero, paciente);
    }

    @Action({ commit: 'onSettoken' })
    public async actualizartoken(token: string) {
        await ssmHttpService.post(API.paciente + '/updateToken/' + token, null);
        return token;
    }

    @Mutation
    public onGetpaciente(res: paciente) {
        this.paciente = new paciente(res);
    }
    @Mutation
    public onSettoken(res: string) {
        this.token = res;
    }

}

export default getModule(pacientesModule);
