





























































import Nueva_platoModule from "@/store/modules/nueva_plato-module";
import pacienteModule from "@/store/modules/paciente-module";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class ViewMasInfoDieta extends Vue {
  @Prop() data_plato_dieta!: any;
  public tab = null;
  public created() {
    //EntrevistaModule.getultimaentrevista();
    this.data_plato_dieta = this.$route.params.data_plato_dieta;
    Nueva_platoModule.getnueva_plato_ficha(this.data_plato_dieta.id_estructura);
  }

  public get receta() {
    return !pacienteModule.paciente.ocultar_recetas;
  }

  public get plato() {
    return Nueva_platoModule.nueva_plato_ficha.find(
      (x) => x.id_estructura === this.data_plato_dieta.id_estructura
    );
  }
  public get cantidades() {
    return !pacienteModule.paciente.ocultar_cantidades;
  }
}
