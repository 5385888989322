import { store } from '@/store/store';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { plato_ficha } from '@/shared/dtos/plato_ficha';

@Module({
    namespaced: true,
    name: 'nueva_platoModule',
    store,
    dynamic: true
})
class nueva_platoModule extends VuexModule {
    public nueva_plato_ficha: plato_ficha[] = [];

    @Action({ commit: 'onGetnueva_plato_ficha' })
    public async getnueva_plato_ficha(id: number) {
        return await ssmHttpService.get(API.nueva_plato + '/ficha/' + id);
    }

    @Mutation
    public onGetnueva_plato_ficha(res: plato_ficha[]) {
        this.nueva_plato_ficha = res;// ? res.map((x) => new plato_ficha(x)) : []
    }

}
export default getModule(nueva_platoModule);